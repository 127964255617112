<template>
  <LiefengContent>
    <template v-slot:title>定义计划</template>
    <template v-slot:toolsbarRight>
      <Button type="primary" icon="md-add" @click="addService"
        >新增服务项</Button
      >
      <Button
        type="error"
        @click="$route.query.id ? $router.push('/archivesdevservice') : $router.push('/archivesplan')"
        icon="ios-arrow-back"
        >返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>

      <!-- 新增/修改 -->
      <LiefengModal
        :title="addChangeTitle"
        :value="addChangeStatus"
        @input="addChangeStatusFn"
        width="600px"
      >
        <template v-slot:contentarea>
          <Form
            :model="formData"
            ref="addChangeForm"
            :rules="formValitor"
            :label-colon="true"
            :label-width="80"
          >
            <FormItem label="服务项" prop="name">
              <Input
              :maxlength="15"
                v-model.trim="formData.name"
                placeholder="请输入服务项名称，不超过15字"
              ></Input>
            </FormItem>
            <FormItem label="服务形式" prop="serviceMode" v-if="addChangeIden">
              <Input
              :maxlength="15"
                v-model.trim="formData.serviceMode"
                placeholder="请输入服务形式，不超过15字"
              ></Input>
            </FormItem>
            <FormItem label="服务目标" v-if="addChangeIden">
              <Input
              :maxlength="400"
                v-model="formData.serviceTarget"
                :rows="5"
                type="textarea"
                show-word-limit
                placeholder="请输入服务目标"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button
            type="info"
            @click="
              cancelModelTip = true;
              addChangeStatusFn(false);
            "
            style="margin-right: 10px"
            >取消</Button
          >
          <Button type="primary" @click="saveAddChange">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/settingservice')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      talbeColumns: [
        {
          title: "服务编号",
          key: "projectCode",
          width: 120,
          align: "center",
        },
        {
          title: "服务项",
          key: "name",
          minWidth: 120,
          align: "center",
        },
        {
          title: "服务形式",
          key: "serviceMode",
          minWidth: 120,
          align: "center",
        },
        {
          title: "服务目标",
          key: "serviceTarget",
          minWidth: 120,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          minWidth: 120,
          align: "center",
          render: (h,params) => {
            return h('div',{
              style: {
                    color: params.row.status == "启用中" ? '' : 'red'
                  },
            },params.row.status)
          }
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.info = params.row.projectId;
                      this.formData = {name:params.row.name};
                      this.addChangeTitle = "修改服务项";
                      this.addChangeIden = false;
                      this.addChangeStatus = true;
                    },
                  },
                  style: {
                    marginRight: "10px",
                  },
                },
                "修改名称"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: ()=> {
                      this.$Modal.confirm({
                        title: "温馨提示",
                        content:`<p>您正在${params.row.status == '启用中' ? '停用' : '启用'}此信息，是否继续？</p>`,
                        onOk: () => {
                          this.$post('/archives/api/pc/serviceProject/updateStatus',{
                            projectId: params.row.projectId,
                            status: params.row.status == '启用中' ? '2' : '1',
                             modifier: parent.vue.loginInfo.userinfo.realName
                          }).then(res => {
                            if(res.code == 200) {
                              this.$Message.success({
                                background: true,
                                content: "操作成功"
                              })
                              this.hadlePageSize({
                                page: this.page,
                                pageSize: this.pageSize
                              })
                            }else {
                                this.$Message.error({
                                background: true,
                                content: res.desc
                              })
                              }
                          })
                        }
                      })
                    }
                  }
                },
                params.row.status == '启用中' ? "停用" : "启用"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      page: 1,
      loading: false,
      pageSize: 20,

      // 新增/修改
      addChangeTitle: "",
      addChangeStatus: false,
      addChangeIden: false, //新增/修改标识 ，true代表新增
      cancelModelTip: true, //关闭弹窗提示标识，true代表需要提示
      formData: {},
      formValitor: {
        name: [
          { required: true, message: "请输入服务项名称", trigger: "blur" },
          { message: "服务项名称不能超过15字", max: 15, trigger: "change" },
        ],
        serviceMode: [
          { message: "服务项形式不能超过15字", max: 15, trigger: "change" },
        ],
      },
    };
  },
  methods: {
    addChangeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addChangeStatus = status;
          },
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    addService() {
      this.formData = {};
      this.addChangeTitle = "新增服务项";
      this.addChangeIden = true;
      this.addChangeStatus = true;
    },
    //新增/修改保存
    saveAddChange() {
      this.$refs.addChangeForm.validate((status) => {
        if (status) {
          if (this.addChangeIden) {  //新增
            this.$post("/archives/api/pc/serviceProject/add", {
              ...this.formData,
              oemCode: parent.vue.oemInfo.oemCode,
              orgCode: parent.vue.loginInfo.userinfo.orgCode
            }).then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "新增成功",
                });
               this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
          });
          this.cancelModelTip = false;
          this.addChangeStatusFn(false);
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
                return;
              }
            });
          } else {  //修改
            this.$post("/archives/api/pc/serviceProject/updateName", {
              name: this.formData.name,
              projectId: this.info,
              modifier: parent.vue.loginInfo.userinfo.realName
            }).then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "修改成功",
                });
                 this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
          });
          this.cancelModelTip = false;
          this.addChangeStatusFn(false);
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
                return;
              }
            });
          }
        }
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/archives/api/pc/serviceProject/queryPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              if (item.status == 1) {
                item.status = "启用中";
              } else if (item.status == 2) {
                item.status = "已停用";
              }
            });
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.page = res.currentPage;
            this.tableData = res.dataList;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-modal-close {
  display: none;
}
</style>